<template>
    <div class="center">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公共模版" name="Public template">
          <!--列表label-->
          <head-layout v-if="activeName === 'Public template'" head-title="作业票模板" :head-btn-options="headBtnOptions"
                       @head-remove="handleDelete" @head-add="headAddPublic"></head-layout>
          <!--列表查询条件和头部按钮-->
          <grid-head-layout ref="gridHeadLayoutPublic" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
              @grid-head-empty="gridHeadEmpty" @switchClick="gridHeadSearch"></grid-head-layout>
          <grid-layout v-if="activeName === 'Public template'" ref="gridLayOutPublic" :table-options="option" :table-data="publicTableData"
            :table-loading="publicLoading" :data-total="publicPage.total" :grid-row-btn="gridRowBtn" :page="publicPage" @page-current-change="onLoadPublic"
            @page-size-change="onLoadPublic" @gird-handle-select-click="selectionChange" @grid-row-detail-click="rowView">
            <template #customBtn="{ row }">
                <el-button v-if="row.isEnable === 1" style="margin: 0 3px" type="text" size="small"
                    @click="diabledTicket(row)">停用</el-button>
                <el-button v-if="row.isEnable === 0" style="margin: 0 3px" type="text" size="small"
                    @click="edit(row)">编辑</el-button>
                <el-button v-if="row.isEnable === 0" style="margin: 0 3px" type="text" size="small"
                    @click="rowDel(row)">删除</el-button>
            </template>
        </grid-layout>
        </el-tab-pane>
        <el-tab-pane label="自定义模版" name="Custom template">
          <!--列表label-->
          <head-layout v-if="activeName === 'Custom template'" head-title="作业票模板" :head-btn-options="headBtnOptions"
                       @head-remove="handleDelete" @head-add="headAdd"></head-layout>
          <!--列表查询条件和头部按钮-->
          <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
                            @grid-head-empty="gridHeadEmpty" @switchClick="gridHeadSearch"></grid-head-layout>
          <grid-layout v-if="activeName === 'Custom template'" ref="gridLayOut" :table-options="option" :table-data="tableData" :table-loading="loading"
                       :data-total="page.total" :grid-row-btn="gridRowBtn" :page="page" @page-current-change="onLoad"
                       @page-size-change="onLoad" @gird-handle-select-click="selectionChange" @grid-row-detail-click="rowView">
            <template #customBtn="{ row }">
              <el-button v-if="row.isEnable === 1" style="margin: 0 3px" type="text" size="small"
                         @click="diabledTicket(row)">停用</el-button>
              <el-button v-if="row.isEnable === 0" style="margin: 0 3px" type="text" size="small"
                         @click="edit(row)">编辑</el-button>
              <el-button v-if="row.isEnable === 0" style="margin: 0 3px" type="text" size="small"
                         @click="rowDel(row)">删除</el-button>
            </template>
          </grid-layout>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { ticketTemplateList, ticketTemplateRemove, disEnableTemplate } from "@/api/workTicket/index"
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            activeName: "Public template",
            templateType: "公共模版",
            loading: true,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            tableData: [],
            publicLoading: true,
            publicPage: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            publicTableData: [],
            selectionList: []
        };
    },
    components: { HeadLayout, GridLayout },

    computed: {
        ...mapGetters(['userInfo']),
        searchColumns() {
            return [
                {
                    prop: "code",
                    type: 'input',
                    span: 3,
                    placeholder: '请输入模板编号',
                },
                {
                    prop: "ticketType",
                    type: "select",
                    span: 3,
                    placeholder: '请选择作业票类型',
                    dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
                    dicData:[],
                    props: {
                        label: "dictValue",
                        value: "dictKey"
                    },
                    dataType: "String",
                },
                {
                    prop: "name",
                    type: 'input',
                    span: 3,
                    placeholder: '请输入模板说明',
                },
                {
                    prop: "version",
                    type:'number',
                    clearable:true,
                    span: 3,
                    placeholder: '请输入版本号',
                },
                {
                    prop: "createUserName",
                    type: 'input',
                    span: 3,
                    placeholder: '请输入起草人',
                },
                {
                    prop: "isEnable",
                    type: "select",
                    span: 3,
                    placeholder: '请选择状态',
                    dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=kicket_template_status",
                    dicData:[],
                    props: {
                        label: "dictValue",
                        value: "dictKey"
                    },
                    dataType: "String",
                },
                {
                    type: 'datetime',
                    prop: "startTime",
                    placeholder: '请选择开始时间',
                    span: 3,
                },
                {
                    type: 'datetime',
                    prop: "endTime",
                    placeholder: '请选择结束时间',
                    span: 3,
                },
                {
                    type: 'switch',
                    prop: "isNew",
                    span: 4,
                    inactiveText: '只展示最新版本'
                },
            ]
        },
        headBtnOptions() {
            return [{
                label: this.$t('cip.cmn.btn.addBtn'),
                emit: "head-add",
                btnOptType: 'add',
            }, {
                label: this.$t('cip.cmn.btn.delBtn'),
                emit: "head-remove",
                btnOptType: 'remove',
            }];
        },
        gridRowBtn() {
            return []
        },
        option() {
            return {
                menuWidth: 150,
                linklabel: "code",
                selectable: (row) => {
                    return row.isEnable === 0;
                },
                column: [
                    {
                        label: '模板编号',
                        prop: "code",
                        align: "left",
                        overHidden: true,
                    },
                    {
                        label: '作业票类型',
                        type: "select",
                        align: "center",
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=work_permit_type",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        prop: "ticketType",
                        overHidden: true,
                        width: 150,
                    },
                    {
                        label: '模板说明',
                        prop: "name",
                        align: "center",
                        overHidden: true,
                        width: 200,
                    },
                    {
                        label: '版本号',
                        prop: "version",
                        align: "center",
                        overHidden: true,
                        width: 100,
                    },
                    {
                        label: '起草人',
                        prop: "createUserName",
                        align: "center",
                        overHidden: true,
                        width: 100,
                    },
                    {
                        label: '创建时间',
                        prop: "createTime",
                        align: "center",
                        overHidden: true,
                        width: 150,
                    },
                    {
                        label: '启用时间',
                        prop: "enableTime",
                        align: "center",
                        overHidden: true,
                        width: 150,
                    },
                    {
                        label: '状态',
                        prop: "isEnable",
                        dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=kicket_template_status",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        dataType: "number",
                        align: "center",
                        overHidden: true,
                        width: 100,
                    },
                ]
            }
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(",");
        },
    },

    mounted() {
        this.onLoadPublic(this.publicPage)
    },

    methods: {
        handleClick(val) {
          if (val.name === "Public template") {
            this.$refs.gridHeadLayoutPublic.searchForm = {};
            this.publicPage.currentPage = 1;
            this.onLoadPublic(this.publicPage);
          } else if (val.name === "Custom template") {
            this.$refs.gridHeadLayout.searchForm = {};
            this.page.currentPage = 1;
            this.onLoad(this.page);
          }
        },
        diabledTicket(row) {
            disEnableTemplate(row.id).then(res => {
                if (res.data.code == 200) {
                  this.$message({
                    type: "success",
                    message: '操作成功！'
                  });
                  if (this.activeName === "Public template") {
                    this.onLoadPublic(this.publicPage);
                  } else if (this.activeName === "Custom template") {
                    this.onLoad(this.page);
                  }
                }
            })
        },
        headAdd() {
            this.$router.push({
                path: `/jobSlip/jobSlipTemplate/add`,
                query: {
                    type: 'add',
                    templateType: "自定义模版"
                }
            })
        },
        headAddPublic() {
            this.$router.push({
                path: `/jobSlip/jobSlipTemplate/add`,
                query: {
                    type: 'add',
                    templateType: "公共模版"
                }
            })
        },
        edit(row) {
            this.$router.push({
                path: `/jobSlip/jobSlipTemplate/edit`,
                query: {
                    type: 'edit',
                    id: row.id
                }
            })
        },
        rowView(row) {
            this.$router.push({
                path: `/jobSlip/jobSlipTemplate/view`,
                query: {
                    type: 'view',
                    id: row.id
                }
            })
        },
        // 列表查询
        gridHeadSearch(searchForm) {
          if (this.activeName === "Public template") {
            this.publicPage.currentPage = 1;
            this.onLoadPublic(this.publicPage,searchForm);
          } else if (this.activeName === "Custom template") {
            this.page.currentPage = 1;
            this.onLoad(this.page,searchForm);
          }
        },
        // 查询重置
        gridHeadEmpty(searchForm) {
          if (this.activeName === "Public template") {
            this.$refs.gridHeadLayoutPublic.searchForm = {};
            this.publicPage.currentPage = 1;
            this.onLoadPublic(this.publicPage,searchForm);
          } else if (this.activeName === "Custom template") {
            this.$refs.gridHeadLayout.searchForm = {};
            this.page.currentPage = 1;
            this.onLoad(this.page,searchForm);
          }
        },
        rowDel(row) {
            this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    ticketTemplateRemove(row.id).then((res) => {
                        if (res.data.code == 200) {
                          if (this.activeName === "Public template") {
                            this.onLoadPublic(this.publicPage);
                          } else if (this.activeName === "Custom template") {
                            this.onLoad(this.page);
                          }
                            this.$message({
                                type: "success",
                                message: this.$t("cip.cmn.msg.success.operateSuccess")
                            });
                        }
                    })
                })
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
                return;
            }
            this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
                confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
                cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
                type: "warning"
            })
                .then(() => {
                    ticketTemplateRemove(this.ids).then((res) => {
                        if (res.data.code == 200) {
                          if (this.activeName === "Public template") {
                            this.onLoadPublic(this.publicPage);
                          } else if (this.activeName === "Custom template") {
                            this.onLoad(this.page);
                          }
                            this.$message({
                                type: "success",
                                message: this.$t("cip.cmn.msg.success.operateSuccess")
                            });
                        }
                    })
                })
        },
        onLoad(page, params = {}) {
            let that = this
            that.page = page;
            that.loading = true;
            ticketTemplateList(page.currentPage, page.pageSize, Object.assign(params, { templateType: '自定义模版', organizationId: that.userInfo.dept_id })).then(res => {
                const data = res.data.data;
                that.$refs.gridLayOut.page.total = data.total;
                that.page.total = data.total;
                that.tableData = data.records;
                that.loading = false;
            });
        },
        onLoadPublic(page, params = {}) {
            let that = this
            that.publicPage = page;
            that.publicLoading = true;
            ticketTemplateList(page.currentPage, page.pageSize, Object.assign(params, { templateType: '公共模版' })).then(res => {
                const data = res.data.data;
                that.$refs.gridLayOutPublic.page.total = data.total;
                that.publicPage.total = data.total;
                that.publicTableData = data.records;
                that.publicLoading = false;
            });
        },
    },
}
</script>

<style scoped lang='scss'>
::v-deep .el-col {
    margin-bottom: 8px !important;
}
::v-deep .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}
::v-deep .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}
</style>
